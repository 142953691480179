<style lang="scss">
  .groups-page {
    .groups-accordion{
      &__btn{
        background-color: #F3F2F7 !important;
        color: #6e6b7b !important;
      }
    }
  }
</style>
<!--eslint-disable-->
<template>
  <div class="groups-page">
    <b-card title="Groups for your enterprise">
      <b-card-text>This page shows all groups in your enterprise.</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="row p-2" v-if="$can('Create', 'Group')">
        <b-col cols="6">
          <b-button @click="$bvModal.show('modal-add-group')" class="btn" variant="primary">Create Group</b-button>
        </b-col>
      </div>

      <b-table
        class="data-table"
        hover
        :items="groups"
        :fields="headers"
        sort-by="name"
      >
        <template #cell(name)="data">
          <router-link :to="{ name: 'group-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap text-underline">
            {{data.item.name}}
          </router-link>
        </template>
        <template #cell(version)="data">
            {{data.item.version}}
        </template>
        <template #cell(secure)="data">
          <span v-if="data.item.is_secure !== null" :class="data.item.is_secure ? 'text-success' : 'text-danger'">
            <i :class="data.item.is_secure ? 'fa-check-circle' : 'fa-times-circle'" class="fas" />
            {{ data.item.is_secure ? "Yes" : "No" }}
          </span>
        </template>
        <template #cell(actions)="data">
          <b-btn v-if="$can('Update', 'Group')"
                 :to="{ name: 'group-edit', params: { uid: data.item.uid } }"
                 variant="primary"
                 class="ml-2">Update</b-btn>
<!--          <b-btn v-if="$can('Delete', 'Group')"-->
<!--                 @click="showGroupModal(data.item, 'modal-delete-group')"-->
<!--                 variant="danger"-->
<!--                 class="ml-2">Delete</b-btn>-->
        </template>
      </b-table>

      <p v-if="groups.length === 0" class="m-2">No Groups available</p>

    </b-card>

    <b-modal id="modal-add-group" title="Add new group" hide-footer>
      <add-group @refresh="refreshGroups()" @close="closeModals()"></add-group>
    </b-modal>

    <b-modal id="modal-update-group" title="Update group" hide-footer>
      <update-group v-if="selectedGroup" :group-existing="selectedGroup" @refresh="refreshGroups()" @close="closeModals()"></update-group>
    </b-modal>


    <b-modal id="modal-delete-group" title="Delete group" hide-footer>
      <delete-modal
        v-if="selectedGroup"
        @close="closeModals()" @delete="deleteGroup(selectedGroup)"
        :subtitle="selectedGroup.name"
        title="Are you sure you wish to delete group"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import GroupService from '../../services/GroupService';
  import AddGroup from './sections/AddGroup.vue';
  import DeleteModal from '../../components/modals/DeleteModal.vue';

  export default {
    name: 'Groups',
    components: {
      AddGroup,
      DeleteModal,
    },
    props: {},
    data() {
      return {
        selectedGroup: null,
        groups: [],
        headers: [
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'version',
            label: 'Version',
          },
          {
            key: 'secure',
            label: 'Secure',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
      };
    },
    mounted() {
      this.getGroups();
    },
    methods: {
      refreshGroups() {
        this.closeModals();
        this.getGroups();
      },
      closeModals() {
        this.$bvModal.hide('modal-add-group');
        this.$bvModal.hide('modal-update-group');
        this.$bvModal.hide('modal-delete-group');
      },
      getGroups() {
        GroupService.getGroups(this.filters).then(res => {
          this.groups = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get groups, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      setSelectedGroup(group) {
        this.selectedGroup = group;
      },
      showGroupModal(group, modalName) {
        this.setSelectedGroup(group);
        this.$bvModal.show(modalName);
      },
      deleteGroup(group) {
        GroupService.deleteGroup(group.uid).then(() => {
          this.$toast.success(`Deleted group ${group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshGroups();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete group, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
