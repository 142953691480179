<style lang="scss">
.add-group-modal{}
</style>

<template>
  <div class="add-group-modal">
    <section class="mb-2">
      <b-form-input v-model="group.name" required class="mb-1" placeholder="Enter name*" />

      <b-form-checkbox v-model="group.debugging_features_allowed" name="check-button" switch>
        Debugging features allowed
      </b-form-checkbox>

      <b-form-checkbox v-model="group.keyguard_disabled" name="check-button" switch>
        Keyguard disabled
      </b-form-checkbox>

      <b-form-checkbox v-model="group.status_reporting_settings" name="check-button" switch>
        Enable Device Status reports
      </b-form-checkbox>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="addGroup()"
      >Add new group</b-button>
    </section>

  </div>
</template>

<script>
  import GroupService from '../../../services/GroupService'

  export default {
    components: {},
    props: {},
    data() {
      return {
        group: {
          debugging_features_allowed: false,
          keyguard_disabled: true,
          name: '',
          status_reporting_settings: true,
          network_escape_hatch_enabled: true,
        },
      };
    },
    mounted() {},
    methods: {
      addGroup() {
        GroupService.createGroup(this.group).then(() => {
          this.$toast.success(`Added group ${this.group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(e => {
          this.$toast.error(`Could not add group: ${e.error} <br>please refresh and try again`, {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        return this.group.name.length > 0;
      },
    },
  };
</script>
